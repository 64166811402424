import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { get } from '@ecster/net/v2';
import LoginPageTemplate from '../features/common/templates/LoginPageTemplate';
import { isFI } from './country';
import { reportError } from './reportError';
import { getConfig } from './MyPagesConfig';
import { createCanaryCookie } from './canaryValues';
import BackendIsDown from './BackendIsDown';

import { GatewayContextProvider } from '../context/gateway-context';

const checkBackendStatus = async () => {
    try {
        const response = await get(`/rest/eos/v1/externalsystem`);
        const acsStatus = response.response.filter(backend => backend.system === 'ACS')[0];
        const ecrStatus = response.response.filter(backend => backend.system === 'ECR')[0];
        const etapStatus = response.response.filter(backend => backend.system === 'ETAP')[0];

        const acs = acsStatus ? acsStatus.isOnline : true; // assume up if no status is found
        const ecr = ecrStatus ? ecrStatus.isOnline : true; // same!
        const etap = etapStatus ? etapStatus.isOnline : true; // same!

        return isFI() ? !!etap : acs || ecr; // we're "up" if one system is up
    } catch (e) {
        reportError(e, 'checkBackendStatus');
        return true;
    }
};

class Gateway extends Component {
    state = {
        isBackendUp: true,
        isLoading: true,
        config: getConfig(),
    };

    async componentDidMount() {
        createCanaryCookie();
        const { loginConfig } = getConfig(); // from session storage
        const loginDisabled = loginConfig?.status === 'DISABLED';
        // no need to check BE status for FI if Login is disabled
        const isBackendUp = (isFI() && loginDisabled) || (await checkBackendStatus());

        this.setState({ isLoading: false, isBackendUp });
    }

    render() {
        const { App } = this.props;
        const { isBackendUp, isLoading } = this.state;

        if (isBackendUp === false) {
            return (
                <React.Suspense fallback="">
                    <LoginPageTemplate isNoMessages>
                        <BackendIsDown />
                    </LoginPageTemplate>
                </React.Suspense>
            );
        }

        return <GatewayContextProvider value={{ isLoading }}>{App}</GatewayContextProvider>;
    }
}

Gateway.propTypes = {
    App: PropTypes.object.isRequired,
};

export default Gateway;
