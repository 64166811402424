// Created by jobo15 2018-05-03

interface DetectDeviceType {
    isDesktop: boolean;
    isTablet: boolean;
    isMobile: boolean;
    isAndroid: boolean;
    isIOS: boolean;
    isLandscape: boolean;
    isPortrait: boolean;
    hasHover: boolean;
    hasFinePointer: boolean;
}

// tablets have ratio below, mobiles above
const ratioBreakPoint = 1.7; // See DeviceTypesSizesDemo in styleguide

/**
 * Check if media queries and screen sizes match particular devices.
 *
 * NOTE! The returned properties do NOT update if the screen size changes.
 *
 * Update 2023-01:
 * - check hover and pointer capabilities to distinguish between desktop and non-desktop (mobile or tablet)
 * - check width / height ratio to distinguish between mobile and tablet
 * - moz-touch-enabled not needed anymore, we're not supporting Firefox 64 or lower
 * - add a userAgent check for IE11 (not really supported, but we still have some users)
 * - Some devices flip window.screen.width and height values depending on how the device is held and some don't
 * - - ios returns 768x1024 in both portrait and landscape mode
 * - - android devices would return 768x1024 in portrait and 1024x768 in landscape
a */
export const detectDevice = (): DetectDeviceType => {
    const hasHover = window.matchMedia('all and (hover: hover)').matches;
    const hasFinePointer = window.matchMedia('all and (pointer: fine)').matches;
    const isIE11 = !!navigator.userAgent.match(/MSIE/);
    // device type
    const isDesktop = window.matchMedia('all and (hover: hover) and (pointer: fine)').matches || isIE11;
    // no hover OR coarse pointer (known bug: Samsung Galaxy incorrectly matches "hover: hover")
    const isMobileOrTablet = window.matchMedia('all and (hover: none), all and (pointer: coarse)').matches && !isIE11;
    // orientation
    const isPortrait = window.matchMedia('all and (orientation: portrait)').matches;
    const isLandscape = window.matchMedia('all and (orientation: landscape)').matches;

    const { width, height } = window.screen;

    // device types continued
    const ratio = Math.max(width, height) / Math.min(width, height);
    const isTablet = isMobileOrTablet && ratio < ratioBreakPoint;
    const isMobile = isMobileOrTablet && ratio >= ratioBreakPoint;

    // platform
    const isAndroid = /android/i.test(window.navigator.userAgent);
    const isIOS =
        (/iPad|iPhone|iPod/i.test(window.navigator.userAgent) ||
            (window.navigator.platform === 'MacIntel' && window.navigator.maxTouchPoints > 1)) &&
        !(window as any).MSStream;

    return {
        isDesktop,
        isTablet,
        isMobile,
        isAndroid,
        isIOS,
        isPortrait,
        isLandscape,
        hasHover,
        hasFinePointer,
    };
};

interface DetectViewtype {
    isMobileView: boolean;
    isTabletView: boolean;
    isMobileOrTabletView: boolean;
    isTabletOrDesktopView: boolean;
    isDesktopView: boolean;
}
export const detectView = (): DetectViewtype => {
    const mobileEnd = '767px';
    const tabletStart = '768px';
    const tabletEnd = '992px';
    const desktopStart = '993px';

    const mobileMatchMedia = window.matchMedia(`all and (max-width: ${mobileEnd})`);
    const tabletMatchMedia = window.matchMedia(`all and (min-width: ${tabletStart}) and (max-width: ${tabletEnd})`);
    const mobileOrTabletMatchMedia = window.matchMedia(`all and (max-width: ${tabletEnd})`);
    const tabletOrDesktopMatchMedia = window.matchMedia(`all and (min-width:${tabletStart})`);
    const desktopMatchMedia = window.matchMedia(`all and (min-width: ${desktopStart})`);

    const isMobileView = mobileMatchMedia.matches;
    const isTabletView = tabletMatchMedia.matches;
    const isMobileOrTabletView = mobileOrTabletMatchMedia.matches;
    const isTabletOrDesktopView = tabletOrDesktopMatchMedia.matches;
    const isDesktopView = desktopMatchMedia.matches;

    return {
        isMobileView,
        isTabletView,
        isMobileOrTabletView,
        isTabletOrDesktopView,
        isDesktopView,
    };
};

export const DesktopDevice = ({ children }) => detectDevice().isDesktop && children;

export const TabletDevice = ({ children }) => detectDevice().isTablet && children;

export const MobileDevice = ({ children }) => detectDevice().isMobile && children;

export const TouchDevice = ({ children }) => (detectDevice().isMobile || detectDevice().isTablet) && children;
