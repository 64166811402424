import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Spinner, Panel } from '@ecster/components';

import { i18n } from '@ecster/i18n';
import { reduxActionSucceeded, reduxActionFinished } from '@ecster/util';

import { withRouter } from 'react-router-dom';
import {
    clearGetAccountBillOverviewState,
    clearGetAccountState,
    dismissGetAccountBillOverviewError,
    dismissGetAccountError,
    getAccount,
    getAccountBillOverview,
} from '../redux/actions';

import AuthenticatedSubPageTemplate from '../../common/templates/AuthenticatedSubPageTemplate';
import { AccountInvoicesPanel } from './AccountInvoicesPanel';

export class AccountInvoicesPage extends React.Component {
    state = {
        accountAvailable: false,
        accountBillOverviewFinished: false,
    };

    componentDidMount() {
        const { getAccount, getAccountBillOverview, customerId, accountRef } = this.props;
        getAccount(customerId, accountRef);
        getAccountBillOverview(customerId, accountRef);
    }

    componentDidUpdate(prevProps) {
        if (reduxActionSucceeded('getAccount', prevProps, this.props)) {
            // eslint-disable-next-line react/no-did-update-set-state
            this.setState({ accountAvailable: true });
        }
        if (reduxActionFinished('getAccountBillOverview', prevProps, this.props)) {
            // eslint-disable-next-line react/no-did-update-set-state
            this.setState({ accountBillOverviewFinished: true });
        }
    }

    render() {
        const { accountRef, bills, customerId } = this.props;
        const bill = bills[accountRef];
        const { accountAvailable, accountBillOverviewFinished } = this.state;
        const noAccountBill = accountBillOverviewFinished && !bill;

        return (
            <AuthenticatedSubPageTemplate
                className="account-invoices-page"
                header={i18n('invoice.account-invoices.page-header')}
                linkTo="/account/overview"
            >
                {noAccountBill ? (
                    <Panel centeredContent>
                        <p>{i18n('invoice.account-invoices.no-invoice')}</p>
                    </Panel>
                ) : (!accountBillOverviewFinished || !accountAvailable) ? (
                    <Spinner id="account-invoices-spinner" isCenter isVisible isCenterPage />
                ) : (
                    <AccountInvoicesPanel accountRef={accountRef} bills={[bill]} customerId={customerId} />
                )}

            </AuthenticatedSubPageTemplate>
        );
    }
}

AccountInvoicesPage.propTypes = {
    accountRef: PropTypes.string.isRequired,
    bills: PropTypes.object.isRequired,
    customerId: PropTypes.number.isRequired,

    // Account
    getAccount: PropTypes.func.isRequired,

    // Account bill
    getAccountBillOverview: PropTypes.func.isRequired,
};

AccountInvoicesPage.defaultProps = {
    createAmortizationFreeMonthError: null,
};

/* istanbul ignore next */
// eslint-disable-next-line
function mapStateToProps({ account, authentication: { person } }, { match }) {
    const { accountRef } = match.params;
    return {
        accountRef,
        account: account.account,
        bills: account.accountBillOverview,
        person,
        customerId: person.id,

        // Account
        getAccountPending: account.getAccountPending,
        getAccountError: account.getAccountError,

        // Account bill
        getAccountBillOverviewPending: account.getAccountBillOverviewPending,
        getAccountBillOverviewError: account.getAccountBillOverviewError,
    };
}

/* istanbul ignore next */
// eslint-disable-next-line
function mapDispatchToProps(dispatch) {
    return {
        // Account
        getAccount: (customerId, accountRef) => dispatch(getAccount(customerId, accountRef)),
        dismissGetAccountError: () => dispatch(dismissGetAccountError()),
        clearGetAccountState: () => dispatch(clearGetAccountState()),

        // Account bill
        getAccountBillOverview: (customerId, accountRef) => dispatch(getAccountBillOverview(customerId, accountRef)),
        dismissGetAccountBillOverviewError: () => dispatch(dismissGetAccountBillOverviewError()),
        clearGetAccountBillOverviewState: () => dispatch(clearGetAccountBillOverviewState()),
    };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AccountInvoicesPage));
