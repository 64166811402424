import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { detectDevice, FadeIn, IEMessage, Logo, TouchDevice } from '@ecster/components';

import { setOrigin, removeAdditionalHeader, X_ECSTER_SESSION_KEY_NAME } from '@ecster/net';
import { getCookie } from '@ecster/cookies';
import { createOriginString } from '@ecster/util';
import LoginPageTemplate from '../common/templates/LoginPageTemplate';
import LoginForm from '../authentication/LoginForm';

import { clearJustLoggedOut, deleteSession, resetLoginState } from '../authentication/redux/actions';
import { showSnackbar } from '../common/redux/actions';

import { LocaleContext } from '../common/LocaleContext';
import { changeLanguage } from '../common/changeLanguage';
import { cache } from '../../common/cacheHandler';
import { ComCheck } from '../common';
import { createOriginCookie, deleteOriginCookie, getOriginCookie } from '../../common/OriginCookie';
import { fetchConfig, setConfig, isConfigInitialized } from '../../common/MyPagesConfig';
import { deleteSessionCookie } from '../../common/sessionCookie';

class LoginPage extends Component {
    state = { locale: null, isReady: false };

    justLoggedOutRef = React.createRef();

    static propTypes = {
        isReviewMode: PropTypes.bool,
        loginStatus: PropTypes.shape().isRequired,
        clearJustLoggedOut: PropTypes.func.isRequired,
        // setJustRedirected: PropTypes.func.isRequired,
        showSnackbar: PropTypes.func.isRequired,
        // logout and reset
        resetLoginState: PropTypes.func.isRequired,
        deleteSession: PropTypes.func.isRequired,
        clearState: PropTypes.func.isRequired,
    };

    static defaultProps = {
        isReviewMode: false,
    };

    async componentDidMount() {
        const { resetLoginState, /* deleteSession, */ clearState, isReviewMode } = this.props;

        // logout and reset everything if user has "browser backed" out from overview
        resetLoginState();
        clearState(isReviewMode);
        cache.clear();

        deleteOriginCookie();
        deleteSessionCookie();
        removeAdditionalHeader(X_ECSTER_SESSION_KEY_NAME);

        if (!isConfigInitialized()) await this.fetchMyPagesConfig();

        this.justLoggedOutRef.current?.focus();

        const { applicationCountry, locale: confLocale } = window.EcsterConfig;

        let locale = confLocale;

        // vh not working properly in mobile web and webview apps, use css variable
        // https://css-tricks.com/the-trick-to-viewport-units-on-mobile/
        const viewportHeight = window.innerHeight * 0.01;
        document.documentElement.style.setProperty('--vh', `${viewportHeight}px`);

        const ref = document.referrer.toLowerCase();
        const isPrefFi = ref.indexOf('/sv/') === -1;

        switch (applicationCountry) {
            case 'SE':
                locale = 'sv-SE';
                break;

            case 'FI':
                if (getCookie('persist_locale') !== 1) {
                    // The user hasn't manually selected a language
                    // Set language to what is current at ecster.fi
                    if (isPrefFi) {
                        locale = 'fi-FI';
                    } else {
                        locale = 'sv-FI';
                    }
                }

                await this.changeLang(locale);

                break;

            default:
                break;
        }

        this.setState({ isReady: true, locale });
    }

    changeLang = async locale => {
        await changeLanguage(locale);
        await this.fetchMyPagesConfig();

        this.setState({
            locale,
            isReady: true,
        });

        this.forceUpdate();
    };

    fetchMyPagesConfig = async () => {
        const myPagesConfig = await fetchConfig();
        setConfig(myPagesConfig);
    };

    render() {
        const {
            loginStatus: { justRedirected },
        } = this.props;

        const { isMobile, isTablet, isDesktop } = detectDevice();

        if (!getOriginCookie()) {
            const originId = createOriginCookie();
            setOrigin(createOriginString('mypages', isMobile, isTablet, isDesktop, window, originId));
        }

        const { locale, isReady } = this.state;

        const { EcsterConfig: conf } = window;

        if (justRedirected) return null;

        return (
            <LoginPageTemplate className="login-page" lazy={false}>
                <ComCheck />
                <FadeIn show={isReady}>
                    <>
                        <TouchDevice>
                            <Logo className="ecster-logo-mobile-fixed" withName width="232px" />
                        </TouchDevice>

                        {/* eslint-disable-next-line react/jsx-no-constructed-context-values */}
                        <LocaleContext.Provider value={{ locale, changeLang: this.changeLang }}>
                            <IEMessage lang={conf?.language || 'sv'} />
                            <LoginForm />
                        </LocaleContext.Provider>
                    </>
                </FadeIn>
            </LoginPageTemplate>
        );
    }
}

/* istanbul ignore next */
function mapStateToProps({ authentication, common }) {
    const { isReviewMode } = common;
    return {
        loginStatus: authentication.loginStatus,
        isReviewMode,
    };
}

/* istanbul ignore next */
function mapDispatchToProps(dispatch) {
    return {
        clearJustLoggedOut: () => dispatch(clearJustLoggedOut()),
        showSnackbar: msg => dispatch(showSnackbar(msg)),
        // safe logout
        resetLoginState: () => dispatch(resetLoginState()),
        deleteSession: sessionKey => dispatch(deleteSession(sessionKey)),
        clearState: isReviewMode =>
            dispatch({ type: 'CLEAR_STATE', isReviewMode, isCustomer: true, justLoggedOut: false }),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(LoginPage);
