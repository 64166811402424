import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button, ButtonGroup, Link, Logo, Option, Select, GeneralErrorMessagePanel } from '@ecster/components';
import { i18n } from '@ecster/i18n';
import { setCookie, getCookie } from '@ecster/cookies';
import { LocaleContext } from '../../common/LocaleContext';
import LoginFooter from '../../home/LoginFooter';
import { LoginResponsivePanel } from '../components/LoginResponsivePanel';
import GlobalNotificationsPanel from '../../../common/GlobalNotificationsPanel';
import { getConfig } from '../../../common/MyPagesConfig.jsx';

const BANK_COOKIE = 'ecster-selected-tupas-bank';
const SESSION_COOKIE = 'ecster-sc';

const BANKS = [
    { label: 'Aktia', value: 'aktia' },
    { label: 'Ålandsbanken', value: 'aland' },
    { label: 'Danske Bank', value: 'danske' },
    { label: 'Handelsbanken', value: 'handelsbanken' },
    { label: 'Nordea', value: 'nordea' },
    { label: 'Oma Säästöpankki', value: 'omasp' },
    { label: 'Osuuspankki', value: 'opbank' },
    { label: 'POP Pankki', value: 'popbank' },
    { label: 'Säästöpankki', value: 'savingsbank' },
    { label: 'S-Pankki', value: 'sbank' },
];

const LoginForm = props => (
    <div className="select-bank">
        <Select
            ariaLabel={i18n('authentication.login.fi.select-placeholder')}
            className="select-bank"
            id="select-tupas-bank"
            value={props.selectedBank || ''}
            noDefault={!!props.selectedBank}
            defaultOption={i18n('authentication.login.fi.select-placeholder')}
            onChange={props.onSelectBank}
        >
            {BANKS.map(bank => (
                <Option key={bank.label} label={bank.label} value={bank.value} />
            ))}
        </Select>
        <ButtonGroup alignCenter>
            <Button type="button" formNoValidate onClick={props.onClickLogin} round name="login-button">
                {i18n('authentication.login.fi.desktop.login-button')}
            </Button>
        </ButtonGroup>
    </div>
);
LoginForm.propTypes = {
    onSelectBank: PropTypes.func.isRequired,
    onClickLogin: PropTypes.func.isRequired,
    selectedBank: PropTypes.string,
};

const LangSwitcher = props => (
    <div className="lang-switcher">
        <Select
            ariaLabel={i18n('general.select-lang.dropdown')}
            id="change-lang"
            noDefault
            border={false}
            value={props.locale || ''}
            onChange={props.onChange}
        >
            <Option value="sv-FI" label={i18n('general.select-lang.swedish')} />
            <Option value="fi-FI" label={i18n('general.select-lang.finnish')} />
        </Select>
    </div>
);
LangSwitcher.propTypes = {
    locale: PropTypes.any,
    onChange: PropTypes.func,
};

class LoginFormFI extends Component {
    state = {
        selectedBank: getCookie(BANK_COOKIE),
        userMessage: '',
        hasTupasProviderError: false,
        loginConfig: getConfig()?.loginConfig,
    };

    componentDidMount() {
        const {
            loginProgress: { status },
        } = this.props;

        /* Status will be empty on start - otherwise we're returning from a failed tupas login */
        if (status === 'USER_CANCEL') {
            this.setState({
                userMessage: i18n('authentication.login.fi.messages.login-cancelled'),
            });
        } else if (typeof status !== 'undefined') {
            // 'CERTIFICATE_ERROR',
            // 'EXPIRED_TRANSACTION',
            // 'CERTIFICATE_ERROR',
            // 'CANCELLED',
            // 'START_FAILED',
            // 'TECHNICAL_ERROR',
            this.setState({
                userMessage: i18n('authentication.login.fi.messages.login-failed'),
            });
        }
    }

    componentDidUpdate(prevProps) {
        const {
            loginStatus: { sessionKey: oldSessionkey },
        } = prevProps;

        const {
            loginStatus: { sessionKey },
        } = this.props;

        if (oldSessionkey !== sessionKey && sessionKey) {
            const { loginProgress } = this.props;
            const { selectedBank } = this.state;
            const startURL =
                loginProgress && loginProgress.startURL && `${loginProgress.startURL}&forcebank=${selectedBank}`;

            setCookie(SESSION_COOKIE, sessionKey);
            window.location.href = startURL;
        }
    }

    onSelectBank = ({ target: { value } }) => {
        this.setState({ selectedBank: value, userMessage: null });
        setCookie(BANK_COOKIE, value);
    };

    onClickLogin = () => {
        const { selectedBank } = this.state;

        if (selectedBank) {
            setCookie(BANK_COOKIE, selectedBank);
            this.startTupasLogin();
        }
    };

    startTupasLogin = () => {
        const { createSession } = this.props;
        const { language, env } = window.EcsterConfig;
        const isDev = env === 'dev';

        const url = new URL(window.location.href);
        url.hash = '#/tupas/login';

        // To develop with NETS: set EcsterConfig.devCanaryValue = 1 in console, Use secureft4 and credentials here: https://www.nets.eu/developer/e-ident/eids/Pages/testusers.aspx;
        createSession({
            type: 'TUPAS',
            country: 'FI',
            locale: { country: 'FI', language },
            returnUrl: url.href,
        });
    };

    persistLangChange = async changeLang => {
        setCookie('persist_locale', 1);
        await changeLang;
        this.setState({ loginConfig: getConfig()?.loginConfig });
    };

    render() {
        const { selectedBank, userMessage, hasTupasProviderError, loginConfig } = this.state;

        if (hasTupasProviderError) {
            return (
                <div className="login-responsive-panel-ctr">
                    <LoginResponsivePanel id="login-page-panel">
                        <GeneralErrorMessagePanel
                            header={i18n('general.error.general.header')}
                            body={i18n('general.error.general.body')}
                            onButtonClick={() => this.setState({ hasTupasProviderError: false })}
                            buttonText={i18n('general.back')}
                            isLink
                        />
                    </LoginResponsivePanel>
                </div>
            );
        }

        return (
            <>
                <div className="login-responsive-panel-ctr">
                    <GlobalNotificationsPanel onlyForcePopup />
                    <LoginResponsivePanel id="login-page-panel">
                        <div id="login-form-fi" className="login-form-fi login-form">
                            <LocaleContext.Consumer>
                                {({ locale, changeLang }) => {
                                    return (
                                        <div className="flex flex-col justify-between gap-4 items-center">
                                            <Logo className="ecster-logo" withName width="190px" />
                                            {loginConfig.status === 'ENABLED' && (
                                                <>
                                                    <h1 className="e-yellow pt-10 h2">
                                                        {i18n('authentication.login.fi.desktop.header')}
                                                    </h1>
                                                    <p className="e-white mb-12">
                                                        {i18n('authentication.login.fi.desktop.body')}
                                                    </p>
                                                    <LoginForm
                                                        selectedBank={selectedBank}
                                                        onClickLogin={this.onClickLogin}
                                                        onSelectBank={this.onSelectBank}
                                                    />
                                                    {userMessage && (
                                                        <p className="text-center text-red-80">{userMessage}</p>
                                                    )}
                                                    <div className="flex flex-row justify-around items-center gap-8">
                                                        <LangSwitcher
                                                            locale={locale}
                                                            onChange={e =>
                                                                this.persistLangChange(changeLang(e.target.value))
                                                            }
                                                        />
                                                        <div className="spacer block bg-white w-[2px]">&nbsp;</div>
                                                        <div className="help">
                                                            <Link
                                                                white
                                                                id="login-help-button"
                                                                strong
                                                                to="/authentication/help"
                                                            >
                                                                <span className="text-[18px]">
                                                                    {i18n('general.help')}
                                                                </span>
                                                            </Link>
                                                        </div>
                                                    </div>
                                                    <LoginFooter
                                                        text={i18n('authentication.login.fi.terms', { danger: true })}
                                                    />
                                                </>
                                            )}
                                            {loginConfig.status === 'DISABLED' && (
                                                <>
                                                    <h1 className="e-yellow pt-10 h3">
                                                        {loginConfig.title ||
                                                            i18n('authentication.login.fi.desktop.header-closed')}
                                                    </h1>
                                                    <div
                                                        className="disabled-login-msg e-white text-xl text-center mb-6"
                                                        dangerouslySetInnerHTML={{ __html: loginConfig.text }}
                                                    ></div>
                                                    <LangSwitcher
                                                        locale={locale}
                                                        onChange={e =>
                                                            this.persistLangChange(changeLang(e.target.value))
                                                        }
                                                    />
                                                </>
                                            )}
                                        </div>
                                    );
                                }}
                            </LocaleContext.Consumer>
                        </div>
                    </LoginResponsivePanel>
                </div>
            </>
        );
    }
}

LoginFormFI.propTypes = {
    loginProgress: PropTypes.object.isRequired,
    loginStatus: PropTypes.object.isRequired,

    // pending and error props used by operationSucceeded
    createSession: PropTypes.func.isRequired,
    createSessionPending: PropTypes.bool.isRequired, // eslint-disable-line react/no-unused-prop-types
    createSessionError: PropTypes.object, // eslint-disable-line react/no-unused-prop-types

    getSessionPending: PropTypes.bool.isRequired, // eslint-disable-line react/no-unused-prop-types
    getSessionError: PropTypes.object, // eslint-disable-line react/no-unused-prop-types
};

LoginFormFI.defaultProps = {
    createSessionError: undefined,
    getSessionError: undefined,
};

export default LoginFormFI;
