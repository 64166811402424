import { get } from '@ecster/net/v2';

const { sessionStorage } = window;

const CONFIG = 'mypages-config';

//   "response" : {
//     "mobileAppShutdownConfig" : {
//       "title" : "Flyttat engagemang",
//       "text" : "<p>Ditt engagemang är flyttat till Rokr</p><p>Logga istället in via <a href='https://rokr.fi/login'>Rokrs inloggning</a></p>",
//       "status" : "DISABLED"
//     },

export const fetchConfig = async () => {
    try {
        const { locale } = window.EcsterConfig;
        const { response } = await get(`/rest/eos/v1/config/mypages?locale=${locale}`);
        // re-map response to how we will interpret it
        return {
            loginConfig: {
                title: response?.mobileAppShutdownConfig?.title || '',
                text: response?.mobileAppShutdownConfig?.text || '',
                status: response?.mobileAppShutdownConfig?.status === 'DISABLED' ? 'DISABLED' : 'ENABLED',
            },
        };
    } catch (e) {
        console.error('Unable to get GUI Config: ', e);
        return {};
    }
};

export const setConfig = config => sessionStorage.setItem(CONFIG, JSON.stringify(config));

export const getConfig = () => JSON.parse(sessionStorage.getItem(CONFIG)) || {};

export const clearConfig = () => sessionStorage.removeItem(CONFIG);

export const isConfigInitialized = () => JSON.stringify(getConfig()) !== '{}';
