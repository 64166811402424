import React from 'react';
import { LabelValue, PanelContent, Panel } from '@ecster/components';
import { i18n } from '@ecster/i18n';

// TODO: move to @ecster/icons
import { SvgIconConstruction } from './images';
import './BackendIsDown.scss';

const BackendIsDown = () => (
    <div className="backend-is-down">
        <Panel>
            <PanelContent narrow centered>
                <SvgIconConstruction />
                <h1 className="h2 e-green120">{i18n('status.backend.down.header')}</h1>
                {i18n('status.backend.down.body', 'p')}
                <footer className="mb-6x">
                    <h2 className="h3">{i18n('status.backend.down.block-card.header')}</h2>
                    <LabelValue
                        label={i18n('status.backend.down.block-card.domestic.label')}
                        value={i18n('status.backend.down.block-card.domestic.phone', { danger: true })}
                    />
                    <LabelValue
                        label={i18n('status.backend.down.block-card.abroad.label')}
                        value={i18n('status.backend.down.block-card.abroad.phone', { danger: true })}
                    />
                </footer>

            </PanelContent>
        </Panel>
    </div>
);

export default BackendIsDown;
